@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Acme;
  src: url("../src/assets/fonts/Acme-Regular.ttf");
}

@font-face {
  font-family: Quicksand;
  src: url("../src/assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
}

:root {
  --primary: #002366;
  --secondary: #0073e6;
}

body {
  margin: 0;
  font-family: Quicksand, "Courier New", Courier, monospace;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.stacked-legend {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.custom-search-input .ant-input {
  border: 2px solid #4b49ac;
  border-radius: 5px;
  outline: none;
}

.custom-search-input .ant-input:focus {
  outline: none;
}

.custom-search-input .ant-btn-primary {
  background-color: #4b49ac;
  border-color: #4b49ac;
}

.custom-search-input .ant-btn-primary:hover,
.custom-search-input .ant-btn-primary:focus {
  background-color: #4b49ac;
  border-color: #4b49ac;
}

/* Custom wizard styles  */
/* File: src/components/Wizard.css */

.wizard-container {
  display: flex;
  gap: 1rem;
  height: 100vh;
}

.wizard-sidebar {
  width: 300px;
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid #ccc; */
}

.wizard-step {
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
  outline: none;
  color: black;
  font-weight: 500;
  background-color: #ddd;
}
.wizard-step.active {
  background-color: #4b49ac;
  color: white;
  font-weight: 700;
}

.wizard-step:hover {
  background-color: #98bdff;
  color: white;
}

.this {
  z-index: 9999;
}

.wizard-content {
  border: 1px solid gray;
  border-radius: 10px;
  flex: 1;
  margin: 0.5rem 0;
  padding: 20px;
}

/* Sidebar styles */
.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
  position: relative;
}

.nav-item,
.navbar {
  z-index: 10000;
}

.filter-popover-width .ant-popover-inner {
  width: 200px !important;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.ant-popover-inner {
  width: auto !important;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

/* .ant-popover-inner {
  width: auto !important;
  margin-right: unset !important;
  margin-top: unset !important;
}  */

.ant-collapse-header .ant-collapse-arrow {
  display: none !important;
}
.sidebar-svg {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  width: 100%;
}

.carousel-bg {
  height: 100vh;
  width: 100vw;
  filter: brightness(40%);
}

.carousel-text {
  z-index: 9999;
}

/* delete this  */
.signup-container {
  position: relative;
}
/* delete this  */
.signup-form {
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  width: 35rem;
  height: 100vh;
  filter: brightness(60%);
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}
